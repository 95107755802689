.card.collapsable {
  box-shadow: none;
}

.card.collapsable .card-header {
  box-shadow: none;
  border-bottom: 1px solid #B5B5B5;
}

.card.collapsable .is-clickable {
  cursor: pointer;
}

.collapsable .collapsable-closed {
  display: none;
}
