.variationen .variation-body {
  display: flex;
  flex-wrap: wrap;
}

.variationen .variation-body .variation-item {
  margin: 10px;
  width: 200px;
}

.is-default {
  border-radius: 15px;
  overflow: hidden;
}
