.Toastify__toast--info {
  background: #3498db;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  border-width: 2px;
  letter-spacing: 1px;
}

.Toastify__close-button {
  color: #fff;
}

.Toastify__toast-body {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  border-width: 2px;
  letter-spacing: 1px;
  text-align: center;
}

.Toastify__toast-container {
  padding: 0;
}

.Toastify__toast-container--top-center {
  top: 0;
}

@keyframes lib-konfigurator__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: hidden; }
  to {
    transform: translate3d(0, 0, 0);
    visibility: visible; } }

@keyframes lib-konfigurator__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
    visibility: visible; }
  to {
    visibility: hidden;
    transform: translate3d(0, -110%, 0); } }

.lib-konfigurator__slide-enter--top-center {
  animation-name: lib-konfigurator__slideInDown; }

.lib-konfigurator__slide-exit--top-center {
  animation-name: lib-konfigurator__slideOutUp; }
