.isometrie {
  position: sticky;
  top: 100px;
  margin-left: 25px;
  margin-bottom: 20px;
  padding-top: 40px;
  height: 373px;
}

.isometrie  svg {
    overflow: hidden;
    width: 100%;
    height: auto;
  }

.isometrie .isometry__room {
  fill: #afdeff;
}

.isometrie .isometry--up {
  display: none;
}

.isometrie .isometry--active {
  fill: #3390D1;
}
