@font-face {
    font-family: 'SangBleu Sans';
    src: url(fonts/SangBleuSans-Regular.eot);
    src: url(fonts/SangBleuSans-Regular.eot?#iefix) format("embedded-opentype"), 
    	url(fonts/SangBleuSans-Regular.woff2) format("woff2"), 
    	url(fonts/SangBleuSans-Regular.woff) format("woff"), 
    	url(fonts/SangBleuSans-Regular.ttf) format("truetype"), 
    	url(fonts/SangBleuSans-Regular.svg#SangBleuSans-Regular) format("svg");
    font-weight: 400;
    font-style: normal
}