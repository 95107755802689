
/* switch default/hires images */
@mixin retinize($file, $type, $width, $height) {
  background-image: url('../pics/' + $file + '.' + $type);
 
  @media (-webkit-min-device-pixel-ratio: 1.5),
         (min--moz-device-pixel-ratio: 1.5),
         (-o-min-device-pixel-ratio: 3/2),
         (min-device-pixel-ratio: 1.5),
         (min-resolution: 1.5dppx) {
    & {
      background-image: url('../pics/' + $file + '-2x.' + $type);
      -webkit-background-size: $width $height;
         -moz-background-size: $width $height;
              background-size: $width $height;
    }
  }
}


/* show/hide responsive index blocks */
@mixin toggle-device-tag($device) {
	#smartphone, #tablet, #tablet-portrait {
		display: none;
	}
	##{$device} {
		display: block;
	}
}

/* responsive media sections */
@mixin responsive($width) {
	@if $width == extra-wide-screens {
    	@media only screen and (min-width: $width-xlarge) { @content; }
  	}
  	@else if $width == wide-screens {
    	@media only screen and (min-width: $width-large) { @content; }
  	}
  	@else if $width == medium-screens {
    	@media only screen and (min-width: $width-medium) { @content; }
  	}
  	@else if $width == medium-screens-portrait {
		@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) { @content; }
  	}
  	@else if $width == medium-screens-semi {
		@media only screen and (min-width: 49em) { @content; }
  	}
  	@else if $width == small-screens {
    	@media only screen { @content; }
  	}
}


@mixin background-size($width, $height: auto) {
	@if $width == cover {
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
	} @else {
	-webkit-background-size:  $width $height;
  	-moz-background-size:  $width $height;
  	-o-background-size:  $width $height;
  	background-size:  $width $height;
  	}
}

@mixin opacity($opacity: 1.0) {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=($opacity*100))";
  	filter: alpha(opacity=($opacity*100));
  	-moz-opacity: $opacity;
  	-khtml-opacity: $opacity;
  	opacity: $opacity;
}
@mixin transition($attribute:all, $duration:0.2s, $easing:ease-in-out) {
	transition: $attribute $duration $easing;
    -webkit-transition: $attribute $duration $easing;
  	-moz-transition: $attribute $duration $easing;
  	-o-transition: $attribute $duration $easing;
}

@mixin rounded($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
@mixin shadow($x, $y, $blur, $color) {
  -webkit-box-shadow: $x $y $blur $color;
     -moz-box-shadow: $x $y $blur $color;
          box-shadow: $x $y $blur $color;
}
@mixin shadow-inset($x, $y, $blur, $color) {
  -webkit-box-shadow: inset $x $y $blur $color;
     -moz-box-shadow: inset $x $y $blur $color;
          box-shadow: inset $x $y $blur $color;
}
@mixin box-sizing {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
@mixin linear-gradient($from, $to) {
  /* Fallback for sad browsers */
  background-color: $to;
  /* Mozilla Firefox */
  background-image:-moz-linear-gradient($from, $to);
  /* Opera */
  background-image:-o-linear-gradient($from, $to);
  /* WebKit (Chrome 11+) */
  background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, $from), color-stop(1, $to));
  /* WebKit (Safari 5.1+, Chrome 10+) */
  background-image: -webkit-linear-gradient($from, $to);
  /* IE10 */
  background-image: -ms-linear-gradient($from, $to);
  /* W3C */
  background-image: linear-gradient($from, $to);
}


@mixin box-shadow($level) {

    @if $level == 1 {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    } @else if $level == 2 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    } @else if $level == 3 {
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    } @else if $level == 4 {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    } @else if $level == 5 {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }

}

